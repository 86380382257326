<!--
 * @Description: 外单
 * @Author: ChenXueLin
 * @Date: 2021-10-12 15:32:42
 * @LastEditTime: 2022-01-25 16:11:51
 * @LastEditors: LiangYiNing
-->
<template>
  <div class="content-view">
    <section class="edit-wrapper">
      <div class="edit-wrapper__body">
        <!-- 基本信息 start -->
        <template>
          <div class="primaryTitle">外单-新装</div>
          <div class="edit-title">基本信息</div>
          <div class="edit-content">
            <el-form
              ref="distributionInfoForm"
              class="form-content"
              label-position="right"
              :inline="true"
              label-width="120px"
            >
              <el-form-item label="客户名称">
                <el-input placeholder="客户名称"></el-input>
              </el-form-item>
              <el-form-item label="联系人">
                <el-input placeholder="联系人"></el-input>
              </el-form-item>

              <el-form-item label="联系电话">
                <el-input placeholder="联系电话"></el-input>
              </el-form-item>
              <el-form-item label="联系地址" prop="expressCorpId">
                <div class="address-box">
                  <e6-city
                    :format="['区县']"
                    :clearable="false"
                    :placeholder="'省/市/县'"
                  ></e6-city>
                  <!--  @selected="citySelect" -->
                  <el-input placeholder="详细地址"></el-input>
                  <!-- <el-button type="text" style="margin-left: 30px"
                    >选择已有地址</el-button
                  > -->
                </div>
              </el-form-item>
              <el-form-item label="要求完成时间" prop="expressNo">
                <el-date-picker
                  type="datetime"
                  placeholder="选择日期"
                  value-format="timestamp"
                  popper-class="special"
                  :picker-options="pickerOptions"
                ></el-date-picker>
                <!-- v-model="value" -->
              </el-form-item>
              <el-form-item label="合同编号">
                <el-input placeholder="合同编号"></el-input>
              </el-form-item>
              <el-form-item label="备注" prop="remarks">
                <el-input
                  placeholder="请输入备注"
                  type="textarea"
                  :rows="3"
                  style="width: 500px"
                  maxlength="100"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
        </template>
        <!-- 基本信息 end -->
        <!-- 任务项设置   start -->
        <template>
          <div class="edit-title">任务项设置</div>
          <div class="edit-content" style="margin-bottom: 20px">
            <div class="associated-info">
              <div class="right-button">
                <el-button type="primary" @click="addScene">
                  添加任务项
                </el-button>
                <el-button type="primary">
                  删除任务项
                </el-button>

                <!-- <el-button type="primary">
                  导入任务项
                </el-button> -->
              </div>
            </div>
            <el-table
              :data="tableData"
              highlight-current-row
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                type="selection"
                width="50"
                align="center"
              ></el-table-column>
              <el-table-column
                show-overflow-tooltip
                v-for="(column, index) in columnData"
                :key="index"
                :prop="column.fieldName"
                :label="column.fieldLabel"
                :min-width="column.width"
                :fixed="column.fixed"
                :align="column.align"
                header-align="center"
              >
                <template slot-scope="{ row }">
                  <div v-if="column.fieldName === 'newName'">
                    <e6-vr-select
                      class="table-select"
                      :data="typeList"
                      v-model="typeId"
                      placeholder="新场景名称"
                      title="新场景名称"
                      clearable
                    ></e6-vr-select>
                  </div>
                  <div v-else-if="column.fieldName === 'newType'">
                    <e6-vr-select
                      class="table-select"
                      :data="typeList"
                      v-model="typeId"
                      placeholder="新场景类型"
                      title="新场景类型"
                      clearable
                    ></e6-vr-select>
                  </div>
                  <span v-else>{{ row[column.fieldName] }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
        <!-- 任务项设置   end -->
      </div>
      <div class="edit-wrapper__footer">
        <el-button class="cancel">取消</el-button>
        <el-button type="primary">确定</el-button>
      </div>
    </section>
    <!-- 添加场景 -->
    <select-scene
      :selectSceneDialog="selectSceneDialog"
      @handleCloseSelectScene="handleClose"
    ></select-scene>
  </div>
</template>

<script>
import base from "@/mixins/base";
import selectScene from "@/components/workOrderManage/selectScene.vue";
export default {
  name: "",
  components: {
    selectScene
  },
  data() {
    return {
      typeId: "",
      typeList: [
        {
          id: 1,
          label: "任务单"
        },
        {
          id: 2,
          label: "服务单"
        },
        {
          id: 3,
          label: "协同单"
        }
      ],
      inspectionColumn: [
        {
          fieldName: "address",
          display: true,
          fieldLabel: "巡检点地址",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "contact",
          display: true,
          fieldLabel: "联系人",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "phone",
          display: true,
          fieldLabel: "联系电话",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ],
      inspectionData: [
        {
          address: "广东省深圳市龙华区龙华大道888号",
          contact: "刘先生",
          phone: "13244556677"
        },
        {
          address: "广东省深圳市龙华区龙华大道888号",
          contact: "刘先生",
          phone: "13244556677"
        }
      ],
      columnData: [
        {
          fieldName: "id",
          display: true,
          fieldLabel: "场景名称",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "name",
          display: true,
          fieldLabel: "场景类型",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "type",
          display: true,
          fieldLabel: "安装组合",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "size",
          display: true,
          fieldLabel: "商品名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },

        {
          fieldName: "count",
          display: true,
          fieldLabel: "商品分类",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "count",
          display: true,
          fieldLabel: "商品类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "desc",
          display: true,
          fieldLabel: "设备编号",
          width: 350,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "newName",
          display: true,
          fieldLabel: "新场景名称",
          width: 350,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "newType",
          display: true,
          fieldLabel: "新场景类型",
          width: 350,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [
        {
          name: "1111",
          type: "台",
          size: "100",
          count: 100
        }
      ],
      addForm: {
        concat: "",
        phone: ""
      },
      // 选择场景
      selectSceneDialog: false,
      addDialog: false //添加巡检点
    };
  },
  mixins: [base],
  methods: {
    //添加任务项
    addScene() {
      this.selectSceneDialog = true;
    },
    //选中数据
    handleSelectionChange(val) {
      console.log(val);
    },
    // 关闭弹框
    handleClose(visibleName) {
      this[visibleName] = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.edit-content {
  padding: 20px 20px 2px;
  .e6-vr-select {
    width: 100%;
  }
  .el-table {
    & tr th > .cell {
      height: 38px;
      line-height: 38px;
      color: #898c91;
      background-color: #ffffff;
    }
    .el-table__body .el-table__row:nth-child(2n) td {
      background-color: #ffffff;
    }
  }
}

.form-content {
  .el-input {
    width: 280px;
  }
}

.edit-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
  .operate-action {
    height: 38px;
  }
}

.associated-info {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}
.table-input {
  width: 300px;
}
/deep/.dialog-box .el-dialog__body {
  padding: 20px;
  box-sizing: border-box;
}
/deep/.edit-content .table-select {
  width: 200px;
}
</style>
